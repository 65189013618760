<template>
  <div>
    <img src="./../assets/logo.png" alt="Logo" class="logo">
    <h1>{{ h1 }}</h1>
    <h2>
      🐻⛓️<a target="_blank" href="https://www.berachain.com/">Berachain</a> validators
    </h2>
    <div class="table">
      <!--        <div class="table-column">-->
      <!--            <p class="p-hidden-header">BEAR</p>-->
      <!--            <p class="p-table-icon"><img src="beraberry-logo-blueberaberry-icon-128px-cropped.png" class="table-icon">!</p>-->
      <!--        </div>-->
      <div class="table-column">
        <p>ID</p>
        <p>1</p>
      </div>
      <div class="table-column">
        <p>Node</p>
        <p><img src="./../assets/blueberrybera-logo.png" class="icon-head"/> Blueberry Bera</p>
      </div>
      <div class="table-column">
        <p>Status</p>
        <p class="node-active">Online</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BerryberaHomepage',
  data: () => {
    return {
      h1: 'Berry Bera',
    }
  }
}
</script>

<style scoped>

</style>
